import tw, { styled } from 'twin.macro';

const StyledPricing = styled.article`
  ${tw`mx-auto mt-10 px-2 md:px-16`};

  h1 {
    ${tw`text-2xl md:text-4xl`};
  }

  .card-pricing {
    ${tw`w-full h-auto mt-16 flex flex-row flex-wrap`}
    
    > div {
      ${tw`p-5`};
    }

    .btn {
      ${tw`mx-auto`}
    }
    
    .detail {
      ${tw`w-full basis-2/3 [min-width: 32rem]`};
      
      h3 {
        ${tw`text-left`};
      }
    }

    .price {
      ${tw`flex-grow flex-shrink-0 bg-slate-50 w-full flex flex-col basis-1/3`}

      h1 {
        ${tw`text-5xl font-bold text-slate-800 mt-10`};
      }

      span {
        ${tw`inline-block w-full text-center text-slate-400 mb-5`};
      }

      .btn {
        ${tw`w-5/6`};
      }
    }
  }

  .card-active {
    ${tw`shadow-green-600/50`};

    .detail {
      ${tw`bg-green-600/5`};
      
    }
  }
`;

export default StyledPricing;
