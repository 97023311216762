import React, { useState } from 'react';

import tw from 'twin.macro';
import { Main } from '@layout';
import { Seo } from '@common';
import { StyledPricing } from '@pricing/styles';
import { StyledToggleXL } from '@common/styles';

import { StaticImage } from 'gatsby-plugin-image';
import queryString from "query-string";

export default function PricingPage() {
  const [includeRespond, setIncludeRespond] = useState(false);
  const [includeDepend, setIncludeDepend] = useState(false);
  const [annualPricing, setAnnualPricing] = useState(false);

  const calcPricing = (component) => {
    let pricing = {};

    if (annualPricing === true) {
      pricing = {
        core: 90,
        respond: 40,
        depend: 80,
      };
    } else {
      pricing = {
        core: 9,
        respond: 4,
        depend: 8,
      };
    }

    switch (component) {
      case 'core':
        return pricing.core;

      case 'respond':
        return pricing.respond;

      case 'depend':
        return pricing.depend;

      default:
        return (
          pricing.core +
          (includeRespond === true ? pricing.respond : 0) +
          (includeDepend === true ? pricing.depend : 0)
        );
    }
  };

  const doBuyNow = () => {
    let qs_params = {
      pricing_annual: annualPricing,
      pricing_respond: includeRespond,
      pricing_depend: includeDepend
    };

    window.sessionStorage.setItem("pricingOptions", JSON.stringify(qs_params));

    // const qs = queryString.stringify(qs_params);
    let url = new URL(window.location);
    let host = "https://www.panorama.im";
    if (url.host !== "www.panorama.im") {
      host = "http://localhost:8000";
    }
    console.log(url);

    window.location.href = `${host}/app/register`;
  }

  const pricingScope = () => {
    return annualPricing === true ? 'per user/annum' : 'per user/month';
  };

  return (
    <Main>
      <Seo
        title="Pricing"
        description="Pricing table for the panorama.im automated incident management service"
      />
      <StyledPricing>
        <div className="contentContainer">
          <h1>Simple Pricing</h1>

          <div className="w-full flex flex-row justify-center align-center">
            <StyledToggleXL htmlFor="togglePricing">
              <input
                type="checkbox"
                id="togglePricing"
                aria-label="Switch between monthly and annual pricing"
                checked={annualPricing}
                onChange={(e) => setAnnualPricing(e.target.checked)}
              />
              <span />
              <h3>
                {annualPricing === true ? 'Annual Pricing' : 'Monthly Pricing'}
              </h3>
            </StyledToggleXL>
          </div>

          <div className="card card-pricing">
            <div className="detail">
              <h3>Panorama Core</h3>
              <span>
                This is the core of panorama.im. You'll get all of the automated
                incident management features that put us on the map!
              </span>

              <StaticImage
                src="../images/pricing.png"
                alt="screenshot"
                quality={100}
                className="mt-5"
              />
            </div>
            <div className="price">
              <h1>£{calcPricing('total')}</h1>
              <span>{pricingScope()}</span>

              <button className="btn" onClick={() => doBuyNow()}>
                Buy Now
              </button>
            </div>
          </div>

          <div
            className={`card card-pricing ${
              includeRespond === true ? 'card-active' : ''
            }`}
          >
            <div className="detail">
              <h3>Panorama Respond</h3>
              <span>Automated and easy on call rotas and escalations</span>

              <StaticImage
                src="../images/pricing.png"
                alt="screenshot"
                quality={100}
                className="mt-5"
              />
            </div>
            <div className="price">
              <h1>+£{calcPricing('respond')}</h1>
              <span>{pricingScope()}</span>

              {includeRespond === true ? (
                <button
                  className="btn btn-danger"
                  onClick={() => setIncludeRespond(false)}
                >
                  Remove
                </button>
              ) : (
                <button className="btn" onClick={() => setIncludeRespond(true)}>
                  Add On
                </button>
              )}
            </div>
          </div>

          <div
            className={`card card-pricing ${
              includeDepend === true ? 'card-active' : ''
            }`}
          >
            <div className="detail">
              <h3>Panorama Depend</h3>
              <span>Customer centric, service dependency mapping</span>

              <StaticImage
                src="../images/pricing.png"
                alt="screenshot"
                quality={100}
                className="mt-5"
              />
            </div>
            <div className="price">
              <h1>+£{calcPricing('depend')}</h1>
              <span>{pricingScope()}</span>

              {includeDepend === true ? (
                <button
                  className="btn btn-danger"
                  onClick={() => setIncludeDepend(false)}
                >
                  Remove
                </button>
              ) : (
                <button className="btn" onClick={() => setIncludeDepend(true)}>
                  Add On
                </button>
              )}
            </div>
          </div>
        </div>
      </StyledPricing>
    </Main>
  );
}
